import * as dompack from 'dompack';
import * as mso from '@mod-mso/webdesigns/mso/mso';

import './widgets/banner/banner';

import './muziekbeleving.scss';


dompack.onDomReady(() => {
  new mso.MSOSite();


  const mediaQuery = window.matchMedia('(min-width: 501px)');
  const mobileImage = document.querySelector('#mobileimg');
  const bannerImage = document.querySelector('.w-banner__bgimage');

  function handleTabletChange(e) {
    if (e.matches) {
      bannerImage.style.display = 'block';
      mobileImage.style.display = 'none';
    }
    else {
      bannerImage.style.display = 'none';
      mobileImage.style.display = 'block';
    }
  }

  try {
    mediaQuery.addEventListener('change', handleTabletChange);
  } catch (e1) {
    try {
      mediaQuery.addListener(handleTabletChange);
    }
    catch (e2) {
      console.error(e2);
    }
  }

  handleTabletChange(mediaQuery);
});
