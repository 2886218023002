import './banner.scss';

import * as dompack from 'dompack';
import * as modal from '@mod-ww/components/modal';


dompack.onDomReady(async () => {
  for (const eWidget of dompack.qSA('.w-banner')) {
    const network = eWidget.dataset.network;
    const ePlayVideo = eWidget.querySelector('.js-play-video');
    if (ePlayVideo) {
      ePlayVideo.addEventListener('click', async (evt) => {
        dompack.stop(evt);
        await modal.runModal('', {
          type: network,
          video: { id: ePlayVideo.dataset.video },
          extraClassNames: ['textvideo'],
        });
      });
    }
  }
});